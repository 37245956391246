const $document = $(document);
const $body = $('body');

$document.on("submit", ".js-ad-filter", function (event) {
  const $form = $(this);

  const $selectedParentCat = $form.find("#parentCategoryFilter");
  const $selectedCat = $form.find("#categoryFilter");
  const $selectedDepartment = $form.find("#departmentFilter");
  const $selectedOrder = $form.find("#orderFilter");
  const $selectedNumberPerPage = $form.find("#numberPerPageFilter");
  const $selectedSearchIn = $form.find("#searchInFilter");
  const $selectedSearchOperation = $form.find("#searchOperationFilter");
  const $selectedSearchValue = $form.find("#searchValueFilter");

  const parentCategoryFilter = $selectedParentCat.val() ? $selectedParentCat.val() : '';
  const categoryFilter = $selectedCat.val() ? $selectedCat.val() : '';
  const departmentFilter = $selectedDepartment.val() ? $selectedDepartment.val() : '';
  const orderFilter = $selectedOrder.val() ? $selectedOrder.val() : '';
  const numberPerPageFilter = $selectedNumberPerPage.val() ? $selectedNumberPerPage.val() : '';
  const searchInFilter = $selectedSearchIn.val() ? $selectedSearchIn.val() : '';
  const searchOperationFilter = $selectedSearchOperation.val() ? $selectedSearchOperation.val() : '';
  const searchValueFilter = $selectedSearchValue.val() ? $selectedSearchValue.val() : '';

  event.preventDefault();

  $.ajax({
    url: window.location.href,
    method: "GET",
    data: {
      parentcategory_filter: parentCategoryFilter,
      category_filter: categoryFilter,
      department_filter: departmentFilter,
      order_filter: orderFilter,
      numberperpage_filter: numberPerPageFilter,
      searchin_filter: searchInFilter,
      searchoperation_filter: searchOperationFilter,
      searchvalue_filter: searchValueFilter,
    },
    beforeSend: function () {
      $body.addClass("is-loading");
    },
    success: function (response) {
      if ($(response).find(".posts-grid").length) {
        const newEntries = $(response).find(".posts-grid").html();
        $(".posts-grid").html(newEntries).show();
        $(".list__error").addClass("d-none");
      } else {
        $(".posts-grid").html("").hide();
        $(".list__error").removeClass("d-none");
      }

      const $newPagination = $(response).find(".js-load-more");
      if ($newPagination.length) {
        $(".js-load-more")
          .attr("href", $newPagination.attr("href"))
          .show();
      } else {
        $(".js-load-more").hide();
      }
    },
    complete: function () {
      $body.removeClass("is-loading");
    },
  });
});

$document.on("change", ".js-select", function (event) {
  // Refresh categories list & reset category filter if necessary (after parent category changes)
  if (event.target.id == 'parentCategoryFilter') {
    const $form = $('.js-ad-filter form');
    const $selectedParentCat = $form.find("#parentCategoryFilter");
    let parentCategoryFilter = $selectedParentCat.val() ? $selectedParentCat.val() : '';

    $("#categoryFilter option").removeClass('d-none');
    $('#categoryFilter option[data-parent-category!="' + parentCategoryFilter + '"]').addClass('d-none');
    $('#categoryFilter option[value="*"]').removeClass('d-none');
    $('#categoryFilter option[value="*"]').prop('selected', true);
  }
  // Submit form
  $('.js-ad-filter').trigger('submit');
});

$document.ready(function(){
  // Move desktop filters content to mobile filters container if necessary
  if (window.matchMedia("(max-width: 767px)").matches) {
    $("#ads-filter-1-desktop").children().appendTo("#ads-filter-1-mobile");
    $("#ads-filter-2-desktop").children().appendTo("#ads-filter-2-mobile");
  }
});
