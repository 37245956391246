const $document = $(document);
const $body = $('body');

$document.on("submit", ".js-evt-filter", function (event) {
  const $form = $(this);

  const $selectedCat = $form.find("#categoryfilter");
  const $selectedType = $form.find("#typefilter");
  const $selectedNiveau = $form.find("#niveaufilter");
  const $selectedOrder = $form.find("#orderfilter");
  const $selectedStartDate = $form.find("#startDateFilter");
  const $selectedEndDate = $form.find("#endDateFilter");
  const $selectedPage = $form.find("#pagedFilter");

  const categoryFilter = $selectedCat.val() ? $selectedCat.val() : '';
  const typeFilter = $selectedType.val() ? $selectedType.val() : '';
  const niveauFilter = $selectedNiveau.val() ? $selectedNiveau.val() : '';
  const orderFilter = $selectedOrder.val() ? $selectedOrder.val() : '';
  const startDateFilter = $selectedStartDate.val() ? $selectedStartDate.val() : '';
  const endDateFilter = $selectedEndDate.val() ? $selectedEndDate.val() : '';
  const pagedFilter = $selectedPage.val() ? $selectedPage.val() : 1;

  event.preventDefault();

  // Get url without parameters
  const newUrl = location.protocol + '//' + location.host + location.pathname;

  $.ajax({
    url: newUrl,
    method: "GET",
    data: {
      category_filter: categoryFilter,
      type_filter: typeFilter,
      niveau_filter: niveauFilter,
      order_filter: orderFilter,
      start_date_filter: startDateFilter,
      end_date_filter: endDateFilter,
      current_page: pagedFilter,
    },
    beforeSend: function () {
      $body.addClass("is-loading");
    },
    success: function (response) {
      if ($(response).find(".posts-grid").length) {
        const newEntries = $(response).find(".posts-grid").html();
        $(".posts-grid").html(newEntries).show();
        $(".list__error").addClass("d-none");
      } else {
        $(".posts-grid").html("").hide();
        $(".list__error").removeClass("d-none");
      }

      const $newPagination = $(response).find(".pagination");
      if ($newPagination.length) {
        $(".pagination").html($newPagination.html()).show();
      } else {
        $(".pagination").html("").hide();
      }
    },
    complete: function () {
      $body.removeClass("is-loading");
    },
  });
});

$document.on("change", ".js-select, .js-date", function () {
  $('#pagedFilter').val(1); // Reset page number after filter changes
  $('.js-evt-filter').trigger('submit');
});

// Ajax pagination
$document.on("click", ".page-numbers", function (e) {
  e.preventDefault();

  const button = $(e.target);
  const currentPage = parseInt($('#pagedFilter').val());
  let pageNumber = parseInt(button.html());

  if (button.hasClass('next')) {
    pageNumber = currentPage + 1;
  } else if (button.hasClass('prev')) {
    pageNumber = currentPage - 1;
  }

  $('#pagedFilter').val(pageNumber);
  $('.js-evt-filter').trigger('submit');
});
